import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { filter, find } from 'lodash';

import DataJobCategories from '../data/jobCategories.json';
import ImgBgExperts from '../img/svg/pageCompany/experts_bg.svg';

import Layout from '../components/Layout';
import Button from '../components/Button';

class JobsPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.categoryDefault = 'all';
    this.state = {
      dataFiltered: [],
      categories: DataJobCategories.items,
      selectedCategory: this.categoryDefault,
      selectedMore: false,
      isMainSVGLoaded: false
    };
  }

  componentDidMount() {
    this.getFilteredJobs();

    // set selected tab from url hash
    let hash = window.location.hash;
    if (hash) {
      hash = hash.substring(1).replace(/%20/g, " ");
      let category = find(this.state.categories, { value: hash });
      if (category) {
        this.handleTab(hash)
      }
    }
  }

  handleTab(e) {
    this.setState({
      selectedCategory: e,
      selectedMore: false
    }, () => {
      this.getFilteredJobs();
    });
    window.location.hash = e;
  }

  tabSelected(e) {
    let { selectedCategory } = this.state;

    return e === selectedCategory ? true : false
  }

  getFilteredJobs() {
    const { selectedCategory } = this.state;
    const { positions } = this.props;
    let data = positions?.items;

    if (selectedCategory !== this.categoryDefault) {
      data = filter(positions?.items, { category: selectedCategory });
    }

    this.setState({ dataFiltered: data });
  }

  render() {
    const { dataFiltered, isMainSVGLoaded, categories, selectedCategory } = this.state;
    const { recruitmentUrl, hero, photo, positions } = this.props;
    const selectedCategoryData = find(this.state.categories, { value: selectedCategory });

    const classNameBackground = classNames('Jobs__hero__background', {
      'Jobs__hero__background--noImage': !isMainSVGLoaded
    });

    return (
      <div className="Jobs">
        <section className="Jobs__hero">
          <div className="Jobs__hero__container">
            <div className="Jobs__hero__content">
              <div className="Jobs__hero__content__top">
                <h1 className="Jobs__hero__title">
                  <ReactMarkdown
                    children={hero.title}
                    rehypePlugins={[rehypeRaw]}
                  />
                </h1>
                <div className="Jobs__hero__text">
                  <ReactMarkdown
                    children={hero.text}
                    rehypePlugins={[rehypeRaw]}
                  />
                </div>
              
                {hero.btnText && hero.btnUrl &&
                  <div className="Jobs__hero__cta mt-5">
                    <Button to={hero.btnUrl}>
                      {hero.btnText}
                    </Button>
                  </div>
                }
              </div>
            </div>

            <div className="Jobs__hero__photo">
              <SVG
                src={ImgBgExperts}
                className="Jobs__hero__photo__imageBg"
              />
              {/* <img
                alt={photo.alt}
                src={'/img/' + photo.image.relativePath}
                className="Jobs__hero__photo__image"
              /> */}
            </div>
          </div>
        </section>

        <section className="Jobs__positions">
          <div className="Jobs__container">
            <div className="Jobs__container__tags">
              <div className="Jobs__tags">
                {categories != null && categories.map((e, i) => (
                  <div
                    key={i}
                    className={classNames('Jobs__tags__item', {
                      'Jobs__tags__item--selected': this.tabSelected(e.value)
                    })}
                    onClick={() => this.handleTab(e.value)}
                  >
                    {e.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="Jobs__container">
            <ul className="Jobs__positions__items">
              {dataFiltered.map((item, i) => (
                <div key={i} className="Jobs__positionItem">
                  <div className="Jobs__positionItem__col">
                    <img src={item.icon.publicURL || item.icon} className="Jobs__positionItem__icon" alt={item.name} />
                  </div>
                  <div className="Jobs__positionItem__col">
                    <h3 className="Jobs__positionItem__title">{item.name}</h3>
                    {item.rate &&
                      <div className="Jobs__positionItem__rate">
                        {item.rate}
                      </div>
                    }
                  </div>
                  <div className="Jobs__positionItem__col">
                    <Button
                      className="Jobs__positionItem__btn"
                      href={item.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {item.label}
                    </Button>
                  </div>
                </div>
              ))}
            </ul>
            {/* {selectedCategory === 'all' && !dataFiltered.length &&
              <div className="Jobs__container-text">
                <ReactMarkdown children={positions.text} rehypePlugins={[rehypeRaw]} />
                <br />
                <Button href={recruitmentUrl} target="_blank">Leave your CV</Button>
              </div>
            }
            {selectedCategory !== 'all' && !dataFiltered.length && selectedCategoryData.linkHr &&
              <div className="Jobs__container-text">
                <ReactMarkdown children={positions.text} rehypePlugins={[rehypeRaw]} />
                <br />
                <Button href={selectedCategoryData.linkHr} target="_blank">Leave your CV</Button>
              </div>
            } */}
          </div>
        </section>

        <section className="Careers__section">
          <div className="Careers__background"></div>
          <div className="Careers__container">
            <div className="Careers__container-text Careers__container-text--3">
              <ReactMarkdown children={positions.text} rehypePlugins={[rehypeRaw]} />
              <br />
              <Button href={selectedCategoryData.linkHr ? selectedCategoryData.linkHr : recruitmentUrl} target="_blank">APPLY</Button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const JobsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout variants={['backgrounded']} meta={frontmatter.meta}>
      <JobsPageTemplate {...frontmatter} />
    </Layout>
  );
};

JobsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default JobsPage;

export const JobsPageQuery = graphql`
  query JobsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "jobs-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }

        recruitmentUrl

        hero {
          title
          text
          btnText
          btnUrl
        }

        photo {
          alt
          image {
            relativePath
            name
          }
        }

        positions {
          text
          items {
            name
            label
            url
            rate
            category
            icon {
              publicURL
              name
            }
          }
        }
      }
    }
  }
`;
